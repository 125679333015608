/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  var year = new Date().getFullYear();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div>
        <main>{children}</main>
        <footer className="hereforyou">
          <ul className="social-icon">
            <li className="grow" data-wow-delay="0"> <a rel="noreferrer" href="https://www.facebook.com/WaveImagingNetwork/" target="_blank"> 
            <i class="fab fa-facebook-f"></i></a> </li>
            <li className="grow" data-wow-delay="0"> <a rel="noreferrer" href="https://twitter.com/WaveImaging" target="_blank"> <i class="fab fa-twitter"></i> </a> </li>
            <li className="grow" data-wow-delay="0"> <a rel="noreferrer" href="https://www.instagram.com/WaveImagingNetwork/" target="_blank"> <i class="fab fa-instagram"></i> </a> </li>
            <li><a rel="noreferrer" href="https://www.linkedin.com/company/waveimagingnetwork/" target="_blank"><i class="fab fa-linkedin-in"></i>
              </a></li>
          </ul>
          <p className="clear"></p>
          <p className="copyright">Copyright © 1984-{year}, RadNet<br />
            <a href="https://www.radnet.com/privacy-statement/" target="_blank">Privacy Statement</a> | <a href="https://www.radnet.com/disclaimer/" target="_blank">Disclaimer</a> | <a href="https://www.radnet.com/hipaa-notification-ca/" target="_blank">HIPAA Notification</a> | <a href="https://www.radnet.com/anti-discrimination-policy" target="_blank">Anti Discrimination Policy</a> | <a href="https://www.radnet.com/accessibility-statement" target="_blank">Accessibility Statement</a> | <a href="https://www.radnet.com/consumer-rights-ca" target="_blank">Your Privacy Choices</a> | <a href="javascript:void(0);" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Privacy Settings</a></p>
            <p class="sites">
          <a href="https://breastlink.com" target="_blank">breastlink.com</a> | <a href="https://xrayhours.com" target="_blank">xrayhours.com</a>
</p>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
